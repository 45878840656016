<template>
    <div class="p-b-10">
        <div class="platform-buttom-list">
            <div :class="tagType===99? 'bottom-button-active':'bottom-button'" v-if="pageType==='customer'&&isAuth('Advertiser_onlineService')" @click="handleChangeTag(99)">信息服务</div>
            <div :class="tagType===1? 'bottom-button-active':'bottom-button'" @click="handleChangeTag(1)">{{listTagName}}</div>
            <div v-if="tagType===2" :class="tagType===2? 'bottom-button-active':'bottom-button'" @click="handleChangeTag(2)">详情</div>
        </div>

        <component ref="buttomComponent" :is="currentComponent + 'Component'" @on-detail="handleShowDetail" @on-updated="handleUpdated" />
    </div>
</template>

<script>
export default {
  components: {
    customerListComponent: () => import('@/components/advertiser/CustomerList'),
    firstPartyListComponent: () => import('@/components/advertiser/FirstPartyList'),
    adxListComponent: () => import('@/components/advertiser/AdxList'),
    onlineserviceComponent: () => import('@/components/advertiser/OnlineService'),
    brandListComponent: () => import('@/components/advertiser/BrandList'),

    customerDetailComponent: () => import('@/components/advertiser/CustomerDetail'),
    firstPartyDetailComponent: () => import('@/components/advertiser/FirstPartyDetail'),
    adxDetailComponent: () => import('@/components/advertiser/AdxDetail'),

    defaultComponent: () => import('./Default')
  },
  data () {
    return {
      // tagType: 99,
      // currentComponent: 'onlineservice',
      tagType: 1,
      currentComponent: 'customerList',
      listTagName: '客户清单'
    }
  },
  created () {
    if (this.isAuth('Advertiser_onlineService')) {
      this.tagType = 99
      this.handleChangeTag(99)
    }
  },
  methods: {
    handleChangeTag (val) {
      this.tagType = val

      if (val === 1) {
        this.currentComponent = this.pageType + 'List'
      } else if (val === 99) {
        this.currentComponent = 'onlineservice'
      } else if (this.detailId) {
        this.currentComponent = this.pageType + 'Detail'
      } else {
        this.currentComponent = 'default'
      }
    },
    handleShowDetail (id) {
      this.$store.commit('set_advertiser_detailId', id)
      this.handleChangeTag(2)
    },
    handleUpdated (detailId) {
      if (this.detailId === detailId) {
        this.$store.commit('set_advertiser_detailId', null)
      }
      this.handleChangeTag(1)
    }
    // createCustomer () {
    //   this.$emit('on-showLeft')
    // }
  },
  computed: {
    pageType () {
      return this.$store.state.advertiser.pageType
    },
    beginUpdate () {
      return this.$store.state.advertiser.beginUpdate
    },
    detailId () {
      return this.$store.state.advertiser.detailId
    }
  },
  watch: {
    pageType (val) {
      switch (val) {
        case 'customer':
          if (this.isAuth('Advertiser_onlineService')) {
            this.tagType = 99
            this.handleChangeTag(99)
          }
          this.listTagName = '客户清单'
          break
        case 'firstParty':
          this.listTagName = '合同甲方清单'
          break
        case 'brand':
          this.listTagName = '品牌库'
          break
        case 'adx':
          this.listTagName = 'ADX'
          break
      }
      this.handleChangeTag(1)
    },
    beginUpdate () {
      if (this.tagType === 1) {
        this.$refs.buttomComponent.reloadData()
      } else {
        this.handleChangeTag(1)
      }
    }
  }
}
</script>
